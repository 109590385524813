/**
 * This is for integrating our site styling and Bootstrap with the Hubspot form, as best as we can.
 */
.hs-form fieldset.form-columns-1,
.hs-form fieldset.form-columns-2,
  {
  max-width: none;
}

.hs-form-field {
  margin-bottom: $form-group-margin-bottom;
  position: relative;

  > label:first-child > span {
    font-weight: 600;
    font-size: 0.9em;
  }

  .hs-form-required {
    color: $danger;
    margin-left: 0.25em;
  }

  .input {
    // margin-right: auto;

    .hs-input:not(.hs-fieldtype-intl-phone) {
      display: block;
      position: relative;
      width: 100%;
      height: $input-height;
      padding: $input-padding-y $input-padding-x;
      font-size: $font-size-base;
      line-height: $input-line-height;
      color: $input-color;
      background-color: $input-bg;
      background-clip: padding-box;
      border: $input-border-width solid $input-border-color;

      // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
      @if $enable-rounded {
        // Manually use the if/else instead of the mixin to account for iOS override
        border-radius: $input-border-radius;
      }

      @else {
        // Otherwise undo the iOS default
        border-radius: 0;
      }

      @include box-shadow($input-box-shadow);
      @include transition($input-transition);

      // Unstyle the caret on `<select>`s in IE10+.
      &::-ms-expand {
        background-color: transparent;
        border: 0;
      }

      // Customize the `:focus` state to imitate native WebKit styles.
      @include form-control-focus();

      // Placeholder
      &::placeholder {
        color: $input-placeholder-color;
        // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
        opacity: 1;
      }

      // Disabled and read-only inputs
      //
      // HTML5 says that controls under a fieldset > legend:first-child won't be
      // disabled if the fieldset is disabled. Due to implementation difficulty, we
      // don't honor that edge case; we style them as disabled anyway.
      &:disabled,
      &[readonly] {
        background-color: $input-disabled-bg;
        // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
        opacity: 1;
      }

      &.invalid.error {
        border-color: $danger;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        &:focus {
          box-shadow: 0 0 0.2rem $danger;
        }
      }
    }

    .inputs-list {
      position: relative;
      display: block;
      padding-left: $form-check-input-gutter;
      margin: auto;

      li {
        list-style: none;
      }

      label {
        margin-bottom: 0; // Override default `<label>` bottom margin
      }

      .hs-input {
        position: absolute;
        width: auto;
        height: auto;
        margin-top: $form-check-input-margin-y;
        margin-left: -$form-check-input-gutter;

        &:disabled~.form-check-label {
          color: $text-muted;
        }
      }

      .hs-form-radio .hs-form-radio-display .hs-input,
      .hs-form-checkbox .hs-form-checkbox-display .hs-input,
      .hs-form-booleancheckbox .hs-form-booleancheckbox-display .hs-input {
        width: auto;
      }
    }
  }

  select.hs-input {

    &[size],
    &[multiple] {
      height: auto;
    }
  }

  textarea.form-control {
    height: auto;
  }
}

.hs-button {
  display: inline-block;
  width: 100%;
  font-weight: $btn-font-weight;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  @each $color,
  $value in $theme-colors {
    &.#{$color} {
      @include button-variant($value, $value);
    }
  }
}

.hs-error-msgs {
  color: $white;
  position: absolute;
  top: 100%;
  left: 0;
  padding-left: 0;
  z-index: 100;
  width: 100%;

  >* {
    display: none;

    &:first-child {
      display: block;
      font-size: 0.75em;
      font-weight: bold;
      background-color: $danger;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;

      label {
        margin: 0.75em;
      }
    }
  }
}

/**
  * Accounts for the input padding on HS two-column forms.
  */
.hs-form fieldset.form-columns-2 .hs-form-field:first-child .hs-error-msgs {

  // HS doesn't follow modern Bootstrap breakpoints, so this must be a media query.
  @media (min-width: 401px) {
    padding-right: 8px;
  }
}

.hs-form fieldset.form-columns-1,
.hs-form fieldset.form-columns-2,
  {
  max-width: none;
}

.hbspt-form form.hs-form fieldset.form-columns-1 .hs-form-field input.hs-input,
.hbspt-form form.hs-form fieldset.form-columns-2 .hs-form-field input.hs-input {
  width: 100%;
}